.event-form {
  .single-step {
    display: none;
  }

  .form-steps {
    display: none;
    padding: 4rem 2rem 0;
    @include srbreakpoints-wrap-content-from(xl) {
      padding: 0 16.8rem;
    }
    @include srbreakpoints-wrap-content-from(m) {
      display: block;
      ul {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        @include srbreakpoints-wrap-content-within(m) {
          justify-content: space-around;
        }

        li {
          margin: 0;
          padding: 0;
          @include srbreakpoints-wrap-content-from(l) {
            width: calc(100% / 7);
          }
          text-align: center;

          &.active, &:hover, &.finish {
            border-bottom: 3px solid #17475B;

            a {
              color: #17475B;
            }
          }

          a {
            display: block;
            width: 100%;
            line-height: 6rem;
            font-weight: 700;
            font-size: 1.6rem;
            color: rgba(#17475B, 0.4);
            text-decoration: none;
          }
        }
      }
    }
  }

  .the-event-form {
    background-color: rgba(#C1D1C7, .2);
    padding: 4rem 2rem;
    @include srbreakpoints-wrap-content-from(xl) {
      padding: 7rem 16.8rem;
    }

    h4 {
      margin: 0 0 5rem;
      padding: 0;
      font-weight: bold;
      font-size: 2.4rem;
      line-height: 2.4rem;
    }

    .form-row {
      padding-bottom: 6rem;
    }

    .step-header, .step-footer {
      display: none;
      @include srbreakpoints-wrap-content-from(m) {

        display: flex;
      }
      justify-content: space-between;
      align-items: center;
      padding: 0 0 6rem;

      h2 {
        font-size: 4.8rem;
        line-height: 4.8rem;
        color: #B9744E;
        margin: 0;
        padding: 0;
      }

      .progress-and-button {
        display: flex;
        align-items: center;
        justify-items: flex-end;
      }

      .progress-bar, .indicator {
        margin-right: 5rem;
        display: block;
        width: 21rem;
        height: 1rem;
        border-radius: 5rem;
        background-color: rgba(#C1D1C7, .2);
      }

      .indicator {
        background-color: #C1D1C7;

        &.step1 {
          width: calc(100% / 6);
        }

        &.step2 {
          width: calc(2 * 100% / 6);
        }

        &.step3 {
          width: calc(3 * 100% / 6);
        }

        &.step4 {
          width: calc(4 * 100% / 6);
        }

        &.step5 {
          width: calc(5 * 100% / 6);
        }

        &.step6 {
          width: calc(6 * 100% / 6);
        }
      }
    }
  }

  .step-footer {
    display: flex !important;
    @include srbreakpoints-wrap-content-within(s) {
      flex-flow: wrap !important;
      padding: 0 !important;
    }

    h6 {
      font-weight: normal;
      font-size: 1.8rem;
      color: #000;
      @include srbreakpoints-wrap-content-within(s) {
        order: 2;
        width: 100%;
        margin-top: 2rem;
      }
    }

    .progress-bar {
      @include srbreakpoints-wrap-content-within(s) {
        display: none !important;
      }
    }

    .progress-and-button {
      @include srbreakpoints-wrap-content-within(s) {
        width: 100% !important;
        .button-on-light {
          text-align: center;
          width: 100%;
        }
      }
    }
  }

  .radio-to-button-container,
  .checkbox-to-button-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .input-row {
      width: 66.666%;
      margin: 0;
    }

    &.two-items {
      @include srbreakpoints-wrap-content-from(l) {
        label.container {
          width: calc(50% - .5rem);
        }
      }
    }

    &.three-items {

      @include srbreakpoints-wrap-content-from(l) {
        label.container {
          width: calc(33.333% - 1rem);
        }
      }
    }

    &.four-items {

      @include srbreakpoints-wrap-content-from(l) {
        label.container {
          width: calc(25% - .5rem);
        }
      }
    }

    label.container {
      display: block;
      position: relative;
      cursor: pointer;
      font-size: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: 100%;
      @include srbreakpoints-wrap-content-between(s, m) {
        margin-bottom: 1rem;
      }
      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        &:checked {
          ~ .checkmark {
            background-color: #17475B;
            color: #fff;
          }
        }
      }

      aside.info-hover {
        display: none;
      }

      &:hover {
        input:not(:checked) ~ .checkmark {
          background-color: rgba(#17475B, 0.6);
          color: #fff;
        }

        @include srbreakpoints-wrap-content-from(xl) {
          aside.info-hover {
            display: flex;
            position: absolute;
            width: 60rem;
            background-color: #fff;
            bottom: 7.8rem;
            left: 50%;
            margin-left: calc(-53.5rem / 2);
            box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.06), 0 13px 13px 0 rgba(0, 0, 0, 0.08), 0 20px 15px 0 rgba(0, 0, 0, 0.04);

            a {
              color: #A3694E;
            }

            &:after {
              top: 100%;
              left: 50%;
              border: solid transparent;
              content: " ";
              height: 0;
              width: 0;
              position: absolute;
              pointer-events: none;
              border-color: rgba(255, 255, 255, 0);
              border-top-color: #ffffff;
              border-width: 22px;
              margin-left: -22px;
            }

            div {
              padding: 3rem;

              h4 {
                font-size: 1.6rem;
                margin: 0 0 1rem;
                padding: 0;
              }

              p {
                margin: 0;
                padding: 0;
                color: #7B7B7B;
                font-size: 1.6rem;
              }
            }
          }
        }
      }

      .checkmark {
        width: 100%;
        display: block;
        border: 2px solid #17475B;
        line-height: 5rem;
        text-align: center;
        text-transform: uppercase;
        font-size: 1.4rem;
        color: #17475B;
        font-weight: 700;
      }
    }
  }

  .checkbox-to-button-container {
    display: flex;
    flex-flow: row wrap;
    margin: 0 -1.5rem;

    &.break:after {
      content: '';
      flex: auto;
    }

    label.container {
      @include srbreakpoints-wrap-content-from(m) {
        width: calc(50% - 3rem);
      }
      @include srbreakpoints-wrap-content-from(l) {
        width: calc(33.333% - 3rem);
      }
      margin: 0 1.5rem 4rem;

      input:checked ~ .checkmark {
        background-color: transparent;

        h4 {
          &:after {
            content: 'Ausgewählt!' !important;
            color: #17475B;
          }
        }
      }

      &:hover {
        input:not(:checked) ~ .checkmark {
          background-color: transparent;
        }

        @include srbreakpoints-wrap-content-from(xl) {
          aside.info-hover {
            left: 5rem;
            bottom: 13rem;
          }
        }
      }

      .checkmark {
        border: 0;
        display: flex;

        img {
          width: 10rem;
          height: 10rem;
          max-width: 10rem;
          max-height: 10rem;
        }

        h4 {
          position: relative;
          margin: 1.5rem 0 0 2rem;
          font-size: 1.8rem;
          line-height: 2.2rem;
          color: #000;
          text-transform: none;

          span {
            display: block;
            font-weight: normal;
            text-align: left;
          }


          &:after {
            position: absolute;
            top: 5rem;
            display: block;
            content: 'Auswählen';
            color: #A3694E;
            line-height: 2.4rem;
          }
        }
      }
    }
  }
}


.input-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin: 0 -1.5rem;

  &.break:after {
    content: '';
    flex: auto;
  }

  .element {
    margin: 0 1.5rem;
    padding: 0 0 2rem;
    @include srbreakpoints-wrap-content-from(m) {
      padding: 0 0 5rem;
    }

    &.line-breaker {
      flex-basis: 100%;
    }

    width: 100%;
    @include srbreakpoints-wrap-content-from(m) {
      width: calc(50% - 3rem);
    }
    @include srbreakpoints-wrap-content-from(l) {
      &.width-160 {
        width: 16rem;
      }
      &.width-250 {
        max-width: 25rem;
      }
      &.width-350 {
        width: 35rem;
        max-width: calc(33.3333% - 3rem);
      }
      &.width-540 {
        width: 54rem;
        max-width: calc(49% - 3rem);
      }
      &.width-full {
        width: 100%;
      }
    }

    label {
      margin: 0 0 .5rem;
      padding: 0;
      display: block;
      color: #17475B;
      font-weight: bold;
      font-size: 1.6rem;
      text-transform: uppercase;
    }

    input[type="text"],
    input[type="email"],
    input[type="number"],
    input[type="datetime-local"],
    input[type="date"],
    input[type="tel"],
    textarea {
      width: 100%;
      line-height: 5rem;
      border: 0;
      padding: 0 1.5rem;
      font-size: 1.4rem;
      color: #7B7B7B;
    }
  }

  .custom-select {
    position: relative;

    select {
      display: none;
    }

    .select-selected {
      background-color: #fff;
      color: #7B7B7B;
      font-size: 1.6rem;
      font-weight: bold;

      &:after {
        position: absolute;
        content: "";
        top: 2rem;
        right: 2rem;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-color: #7B7B7B transparent transparent transparent;
      }

      .select-arrow-active:after {
        border-color: transparent transparent #7B7B7B transparent;
        top: 7px;
      }
    }

    .select-items div, .select-selected {
      color: #7B7B7B;
      padding: 0 0 0 1.5rem;
      line-height: 5rem;
      cursor: pointer;
    }

    .select-items {
      position: absolute;
      background-color: #fff;
      top: 100%;
      left: 0;
      right: 0;
      z-index: 99;
    }

    .select-hide {
      display: none;
    }

    .select-items div:hover, .same-as-selected {
      background-color: rgba(#17475B, 0.4);
    }
  }
}