/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/3908ba");


@font-face {
  font-family: 'freight';
  font-style: normal;
  font-weight: normal;
  src: url('../assets/fonts/3908BA_0_0.eot');
  src: url('../assets/fonts/3908BA_0_0.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/3908BA_0_0.woff2') format('woff2'), url('../assets/fonts/3908BA_0_0.woff') format('woff'), url('../assets/fonts/3908BA_0_0.ttf') format('truetype');
}

@font-face {
  font-family: "Neutraface Text";
  src: url("../assets/fonts/NeutrafaceText-Book.woff2") format("woff2"), url("../assets/fonts/NeutrafaceText-Book.woff") format("woff");
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "Neutraface Text";
  src: url("../assets/fonts/NeutrafaceText-Bold.woff2") format("woff2"), url("../assets/fonts/NeutrafaceText-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

[data-ekgrid-hide~="s-m"] {
  @include srbreakpoints-wrap-content-between(s, m) {
    display: none !important;
  }
}

[data-ekgrid-hide~="l-xl"] {
  @include srbreakpoints-wrap-content-between(l, xl) {
    display: none !important;
  }
}

html {
  font-size: 62.5%;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  font-family: "Neutraface Text", sans-serif;
  font-kerning: normal;
  font-variant-ligatures: common-ligatures, contextual;
  -moz-font-feature-settings: "kern", "liga", "clig", "calt";
  -ms-font-feature-settings: "kern", "liga", "clig", "alt";
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt";
  font-feature-settings: "kern", "liga", "clig", "calt";
  background: #eee;
  font-size: 1.6rem;
  line-height: 2.4rem;
}

*,
::before,
::after {
  box-sizing: border-box;
  outline: none;
  -webkit-tap-highlight-color: transparent;
}

.hide {
  display: none;
}

::-ms-clear {
  display: none;
}

h1, h2,  h3, h4, h5, h6 {
  margin: 0;
}

img {
  max-width: 100%;
}

header {
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  @include srbreakpoints-wrap-content-from(l) {
    top: 2rem;
    margin: 0;
    padding: 4rem 0;
  }

  .navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    @include srbreakpoints-wrap-content-from(l) {
      justify-content: space-around;
      padding: 0;
    }

    h3 {
      padding: 0;
      margin: 0;

      img {
        width: 16rem;
      }
    }

    a {
      color: #fff;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    ul {
      @include srbreakpoints-wrap-content-from(m) {
        display: flex;
        list-style: none;
      }
      li {
        margin-right: 3rem;
        @include srbreakpoints-wrap-content-from(xl) {
          margin-right: 5rem;
        }
        font-weight: bold;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

#toggle-mobile-menu {
  @include srbreakpoints-wrap-content-within(s) {
    width: 2rem;
    height: 2rem;
    display: block;
    background-image: url("../assets/images/icon-burger.svg");
  }
}

.button-on-dark {
  display: inline-block;
  line-height: 5rem;
  border: 1px solid #fff;
  padding: 0 2.5rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    text-decoration: none !important;
    background-color: rgba(#000, .2);
  }
}

.button-on-light {
  display: inline-block;
  line-height: 5rem;
  color: #fff;
  padding: 0 2.5rem;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: bold;
  background-color: #A3694E;
  text-decoration: none;

  &:hover {
    text-decoration: none !important;
    background-color: #fff;
    color: #A3694E;
  }
}

main {
  background-color: #fff;
}

.decoration-stripe-white {
  position: relative;
  margin-bottom: 5.5rem;
  &:after {
    left: 0;
    right: 0;
    display: block;
    content: '';
    margin: 3rem auto 2rem;
    width: 8.2rem;
    height: .8rem;
    position: absolute;
    background-image: url('../assets/images/stripe-white.svg');
  }
}

.decoration-stripe-blue {
  position: relative;
  margin-bottom: 5.5rem;
  &:after {
    left: 0;
    right: 0;
    display: block;
    content: '';
    margin: 3rem auto 2rem;
    width: 8.2rem;
    height: .8rem;
    position: absolute;
    background-image: url('../assets/images/stripe-blue.svg');
  }
}

.on-dark-blue {
  background-color: #17475B;

  i.order {
    color: rgba(#fff, 0.05);
  }

  h6 {
    color: rgba(#fff, 0.6);
  }

  h2 {
    color: #fff;
  }

  p {
    color: #fff;
  }
  i.stripe {
    background-image: url('../assets/images/stripe-grass.svg');
  }
}

.on-light-green {
  background-color: #DFE8E2;

  i.order {
    color: rgba(#17475B, 0.05);
  }

  h6 {
    color: #17475B;
  }

  h2 {
    color: #B9744E;
  }

  p {
    color: #7B7B7B;
  }
  i.stripe {
    background-image: url('../assets/images/stripe-blue.svg');
  }
}

.on-light-blue {
  background-color: #DFF3F7;

  i.order {
    color: rgba(#17475B, 0.05);
  }

  h6 {
    color: #17475B;
  }

  h2 {
    color: #B9744E;
  }

  p {
    color: #7B7B7B;
  }

  i.stripe {
    background-image: url('../assets/images/stripe-beige.svg');
  }
}

.on-grass {
  background-color: #E6EDEF;

  i.order {
    color: rgba(#17475B, 0.05);
  }

  h6 {
    color: rgba(#17475B, 0.2);
  }

  h2 {
    color: #B9744E;
  }

  p {
    color: #7B7B7B;
  }

  i.stripe {
    background-image: url('../assets/images/stripe-beige.svg');
  }
}

.on-white {
  background-color: #fff;

  i.order {
    color: rgba(#17475B, 0.05);
  }

  h6 {
    color: #17475B;
  }

  h2 {
    color: #B9744E;
  }

  p {
    color: #7B7B7B;
  }
  i.stripe {
    background-image: url('../assets/images/stripe-blue.svg');
  }
}


.text-section {
  padding: 4rem 0 0;
  position: relative;
  @include srbreakpoints-wrap-content-from(l) {
    width: 35rem;
  }

  i.order {
    position: absolute;
    font-family: 'freight';
    font-size: 12.8rem;
    font-style: normal;
    left: -20%;
    z-index: 0;
  }

  h6 {
    letter-spacing: 1.78px;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.9rem;
    margin-bottom: 1rem;
  }

  h2 {
    font-size: 4.8rem;
    line-height: 4.8rem;
    margin-bottom: 2rem;
  }

  p {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }

}

i.stripe {
  margin-top: 2rem;
  display: block;
  width: 8.2rem;
  height: 1rem;
  background-repeat: no-repeat;
  &.beige {
    background-image: url('../assets/images/stripe-beige.svg');
  }
}


footer {
  .diamond-section {
    @include srbreakpoints-wrap-content-from(l) {
      background-image: url("../assets/images/bg_footer.jpg");
      background-size: 144rem 100rem;
    }
  }
}

.bx-pager {
  padding-top: 2rem;
  font-size: 0;
  display: flex;
  justify-content: center;
  .bx-pager-item {
    margin-right: 1rem;
    transform: rotate(-45deg);
    &:last-of-type {
      margin-right: 0;
    }
  }
  .bx-pager-link {
    display: block;
    width: 1rem;
    height: 1rem;
    background-color: rgba(#17475B, 0.2);
    &.active {
      background-color: #17475B;
    }
  }
}

.responsive-tabs__heading { display: none;}
.responsive-tabs__list__item { display: inline; cursor:pointer;}


@media (max-width: 1023px) {
  .responsive-tabs__list { display: none;}
  .responsive-tabs__heading { display: block; cursor: pointer; }
  .responsive-tabs__panel--closed-accordion-only { display: none;}
}

@media (min-width: 1024px) {
  .responsive-tabs__panel--closed-accordion-only { display: block !important;}
}