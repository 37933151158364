@import '../node_modules/@superreal/srbreakpoints/src/styles/srbreakpoints.scss';
@import '../node_modules/@superreal/srgrid/src/styles/srgrid.scss';
@import '../node_modules/node-normalize-scss/normalize';
@import "settings";
@import "mixins";
@import "base";
@import "home";
@import "eventform";
@import "datepicker";

.image-with-text {
  padding: 4rem 2rem;
  display: flex;

  .text-section {
    z-index: 2;
  }

  @include srbreakpoints-wrap-content-within(s) {
    flex-wrap: wrap;
  }
  @include srbreakpoints-wrap-content-within(m) {
    .text-section {
      padding: 0;
    }
  }

  @include srbreakpoints-wrap-content-from(l) {
    padding: 16.8rem 16.8rem 16.8rem;
    justify-content: center;
    align-items: center;
    position: relative;
    &.less-space {
      padding-top: 0;
    }
  }

  &.image-stripes {
    @include srbreakpoints-wrap-content-from(l) {
      &:after {
        content: '';
        width: 25rem;
        height: 24.8rem;
        position: absolute;
        background-image: url('../assets/images/brown-stripes.svg');
        background-repeat: no-repeat;
      }
    }
  }

  &.image-stripes-grass {
    @include srbreakpoints-wrap-content-from(l) {
      &:after {
        content: '';
        width: 50rem;
        top: -16.8rem;
        height: 49.5rem;
        position: absolute;
        background-size: cover;
        background-image: url('../assets/images/grass-stripes.svg');
        background-repeat: no-repeat;
      }
    }
  }

  &.image-stripes-vertical,
  &.image-stripes-vertical-top {
    @include srbreakpoints-wrap-content-from(l) {
      &:after {
        content: '';
        width: 49.2rem;
        height: 50.1rem;
        position: absolute;
        background-image: url('../assets/images/grass-stripes-vertical.svg');
        background-repeat: no-repeat;
        bottom: 0;

      }
    }
  }
  &.image-stripes-vertical-top {
    @include srbreakpoints-wrap-content-from(l) {
      &:after {
        top: -16.8rem;
      }
    }
  }

  &.image-double-arrows {
    @include srbreakpoints-wrap-content-from(l) {
      &:after {
        content: '';
        width: 50rem;
        height: 54.3rem;
        position: absolute;
        background-image: url('../assets/images/arrows-grass-full.svg');
        background-repeat: no-repeat;
        bottom: 0;

      }
    }
  }

  &.image-right {
    @include srbreakpoints-wrap-content-within(s) {
      .text-section {
        order: 2;
      }
    }

    @include srbreakpoints-wrap-content-within(m) {
      .text-section {
        margin-right: 2rem;
      }
    }
    @include srbreakpoints-wrap-content-from(l) {
      .text-section {
        margin-right: 10rem;
      }
    }

    @include srbreakpoints-wrap-content-from(xl) {
      .text-section {
        margin-left: 0;
      }
    }

    &.image-stripes,
    &.image-stripes-vertical,
    &.image-stripes-vertical-top {
      &:after {
        right: 0;
      }
    }

    &.image-double-arrows,
    &.image-stripes-grass {
      &:after {
        left: 0;
      }
    }
  }

  &.image-left {
    .text-section {
      order: 2;
    }

    @include srbreakpoints-wrap-content-within(m) {
      .text-section {
        margin-left: 2rem;
      }
    }
    @include srbreakpoints-wrap-content-from(l) {
      .text-section {
        margin-left: 10rem;
      }
    }

    &.image-stripes,
    &.image-stripes-vertical,
    &.image-stripes-vertical-top {
      &:after {
        left: 0;
      }
    }

    &.image-double-arrows,
    &.image-stripes-grass {
      &:after {
        right: 0;
      }
    }
  }

  .additional-images {
    display: none;
    @include srbreakpoints-wrap-content-from(l) {
      display: flex;
      a {
        display: block;
        margin-right: 2rem;
        width: 6rem;
        height: 6rem;
        &:last-child {
          margin-right: 0;
        }
      }
      img {
        border-radius: 50%;
      }
    }
  }


  .button-on-light {
    border: 1px solid #B9744E;
  }
  .stripe {
    display: block;
    margin-top: 2rem;
    @include srbreakpoints-wrap-content-from(l) {
      margin-top: 3rem;
    }
  }
}

.double-arrow-section {
  background-repeat: no-repeat;
  padding: 2rem 1.5rem;
  background-position: center;
  @include srbreakpoints-wrap-content-from(m) {
    padding: 4rem;
  }
  @include srbreakpoints-wrap-content-from(l) {
    height: 100rem;
    padding: 5rem;
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 90rem;
      top: 5rem;
      left: 60.8rem;
      width: 42rem;
      background-repeat: no-repeat;
      background-image: url("../assets/images/double-arrows-white.svg");
      background-size: auto 90rem;
    }
  }

  .content-wrapper {
    width: 100%;
    background-color: #fff;
    padding: 0 2rem 4rem;
    @include srbreakpoints-wrap-content-from(l) {
      width: 56rem;
      height: 90rem;
      padding: 0 12rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
  }

  .button-on-light {
    @include srbreakpoints-wrap-content-between(s, m) {
      width: 100%;
    }
    text-align: center;
  }
}

.middle-circle-section {
  background-repeat: no-repeat;
  padding: 2rem 1.5rem;
  background-position: center;
  background-size: cover;
  @include srbreakpoints-wrap-content-from(m) {
    padding: 4rem;
  }
  @include srbreakpoints-wrap-content-from(l) {
    margin: 0 16.8rem;
    border-bottom: 16.8rem solid #fff;
    padding: 0;
    height: 83rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content-wrapper {
    width: 100%;
    background-color: #fff;
    padding: 0 2rem 4rem;
    z-index: 2;
    @include srbreakpoints-wrap-content-from(l) {
      width: 51.5rem;
      height: 51.5rem;
      padding: 0 7rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border-radius: 50%;
      text-align: center;
      .text-section {
        i.order {
          left: 0;
          right: 0;
        }
        i.stripe {
          margin: 0 auto;
          background-image: url("../assets/images/stripe-apricot.svg");
        }
      }
    }
  }

  .button-on-light {
    @include srbreakpoints-wrap-content-between(s, m) {
      width: 100%;
    }
    text-align: center;
  }
}

.diamond-section {
  padding: 12rem 2rem;
  text-align: center;
  @include srbreakpoints-wrap-content-from(l) {
    height: 100rem;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    .content-wrapper {
      width: 67.6rem;
      height: 67.6rem;
      background-color: transparent;
      background-image: url("../assets/images/diamond-grass.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .text-section {
    padding: 0;
  }
}

.footer-navigation {
  background-color: #fff;
  @include srbreakpoints-wrap-content-from(m) {
    padding: 0 4rem;
  }

  .side-menu-content {
    aside.meta-menu {
      position: relative;
      width: 100%;
      left: 0;
      padding-bottom: 2rem;
      @include srbreakpoints-wrap-content-from(m) {
        margin-top: 3.5rem;
        padding-bottom: 0;
      }
    }
  }
}


.overlay {
  height: 0;
  width: 100%;
  position: fixed; /* Stay in place */
  z-index: 1000; /* Sit on top */
  left: 0;
  top: 0;
  background-color: #fff;
  overflow-y: hidden;
  transition: 0.7s;
}

.side-menu-content {
  padding: 2rem;
  @include srbreakpoints-wrap-content-from(m) {
    display: flex;
    flex-flow: row wrap;
    max-width: 111rem;
    margin: 0 auto;
    padding: 6rem 0 8rem;
    justify-content: space-between;
    align-items: center;
  }

  .logo-row {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 4.5rem;
    @include srbreakpoints-wrap-content-from(m) {
      margin-bottom: 0;
    }

    h3 {
      margin: 0;
    }

    #close-overlay {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      background-image: url("../assets/images/icon-close.svg");
      background-repeat: no-repeat;
    }

    #go-up {
      display: block;
      width: 1.8rem;
      height: 1.2rem;
      background-image: url("../assets/images/icon-arrow-up.svg");
      background-repeat: no-repeat;
    }
  }

  ul.main-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    @include srbreakpoints-wrap-content-from(m) {
      display: flex;
      text-align: right;
    }

    li {
      margin-bottom: 2rem;
      @include srbreakpoints-wrap-content-from(m) {
        margin-bottom: 0;
        margin-right: 2.5rem;
        &:last-child {
          margin-right: 0;
        }
      }
      @include srbreakpoints-wrap-content-from(l) {
        margin-right: 5rem;
      }

      a {
        font-weight: bold;
        text-decoration: none;
        color: #17475B;
      }
    }
  }

  aside.meta-menu {
    position: absolute;
    left: 2rem;
    bottom: 0;
    width: calc(100% - 4rem);
    padding: 3rem 0 4rem;
    border-top: 1px solid rgba(#979797, 0.1);
    @include srbreakpoints-wrap-content-from(m) {
      padding: 3.5rem 0 0;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    ul.meta-nav {
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: center;
      @include srbreakpoints-wrap-content-from(m) {
        display: flex;
      }

      li {
        margin-bottom: 2rem;
        @include srbreakpoints-wrap-content-from(m) {
          margin-bottom: 0;
          margin-right: 5rem;
          &:last-child {
            margin-right: 0;
          }
        }

        a {
          font-weight: bold;
          color: rgba(#464646, 0.24);
          text-decoration: none;
        }
      }
    }

    ul.social-nav {
      margin: 0;
      padding: 0;
      list-style: none;
      text-align: center;
      display: flex;
      justify-content: center;

      li {
        margin-right: 1rem;

        i {
          display: block;
          width: 3.2rem;
          height: 3.2rem;
          background-repeat: no-repeat;
          &:hover {
            opacity: 0.7;
          }
          &.icon-fb {
            background-image: url("../assets/images/icon-social-facebook.svg");
          }

          &.icon-ig {
            background-image: url("../assets/images/icon-social-instagram.svg");
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}


.content-main-teaser {
  height: 62rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;

  .info-area {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    h1 {
      color: #fff;
      font-weight: normal;
      font-size: 5.6rem;
      line-height: 6.4rem;
    }

    .content-wrapper {
      height: 100%;
      background-color: rgba(#000, 0.2);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: wrap;
      flex-direction: column;
    }
  }

  @include srbreakpoints-wrap-content-from(l) {
    height: 54rem;
    padding: 2rem;
  }
}

.simple-hero-text {
  padding: 4rem 2rem;
  @include srbreakpoints-wrap-content-from(m) {
    padding: 12rem 6rem 0;
    text-align: center;
  }
  @include srbreakpoints-wrap-content-from(l) {
    padding: 12rem 0 0;
  }

  h3 {
    font-weight: normal;
    font-size: 3.2rem;
    line-height: 4.8rem;
    color: #C08260;
    @include srbreakpoints-wrap-content-from(l) {
      max-width: 70rem;
      margin: 0 auto;
    }
  }
}

.extended-hero-text {
  padding: 0 2rem 4rem;
  @include srbreakpoints-wrap-content-from(m) {
    padding: 0 6rem 8rem;
    text-align: center;
  }
  @include srbreakpoints-wrap-content-from(l) {
    padding: 0 0 8rem;
  }
  .text-section {
    margin: 0 auto;
    max-width: 50rem;
    i.order {
      left: 0;
      right: 0;
    }
  }
  p {
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
  }
  &.formatted-text {
    text-align: left;
    p {
      max-width: 80%;
    }
  }
}

.fact-list-section {
  padding: 4rem 2rem;

  .text-section {
    padding: 0;
    z-index: 2;

  }

  @include srbreakpoints-wrap-content-from(l) {
    padding: 16.8rem 0 11.8rem;
    justify-content: center;
    align-items: center;
    position: relative;
    .text-section {
      margin-left: 16.8rem;
      max-width: 54rem;
    }
  }

  .fact-list {
    list-style: none;
    margin: 4rem 0 0;
    padding: 0;
    @include srbreakpoints-wrap-content-from(m) {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-items: flex-start;
      li {
        width: calc(50% - 1.5rem);
        flex-basis: calc(50% - 1.5rem);
        margin-bottom: 5rem;
        display: flex;
        align-items: center;
        align-content: flex-start;
        img {
          align-self: flex-start;
          width: 10rem;
          height: 10rem;
          margin-right: 2rem;
        }
      }
    }
    @include srbreakpoints-wrap-content-from(l) {
      margin: 11rem 16.8rem 0;
      li {
        width: calc(33.333% - 2rem);
        flex-basis: calc(33.333% - 3rem);
      }
    }

    h3 {
      font-size: 1.8rem;
      color: #000000;
      margin: 0 0 1rem;
    }

    p {
      margin: 0 0 2rem;
      color: #7B7B7B;
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }
}


.room-overview-section {
  padding: 4rem 2rem;
  @include srbreakpoints-wrap-content-from(l) {
    padding: 0 0 13.4rem;
    position: relative;
    .text-section {
      padding: 16.8rem 0 9rem;
      margin-left: 16.8rem;
      width: 54rem;
    }
  }

  @include srbreakpoints-wrap-content-between(s, m) {
    .responsive-tabs-wrapper {
      padding: 4rem 0 0;
      h2 {
        color: #000000;
        font-size: 2.4rem;
        line-height: 2.4rem;
        margin-bottom: 3rem;
      }
    }
  }

  @include srbreakpoints-wrap-content-from(l) {
    .responsive-tabs-wrapper {
      padding: 0 16.8rem;

      .responsive-tabs__list {
        margin: 0;
        padding: 0;
        display: flex;

        .responsive-tabs__list__item {
          flex-grow: 1;
          text-align: center;
          line-height: 6rem;
          font-size: 1.6rem;
          font-weight: bold;
          color: rgba(#17475B, 0.4);
          border-bottom: 1px solid rgba(#17475B, 0.4);

          &.responsive-tabs__list__item--active {
            color: #17475B;
            border-bottom: 3px solid #17475B;
          }
        }
      }
    }
  }

  .room-tab {
    @include srbreakpoints-wrap-content-from(l) {
      padding: 5rem 0 4rem;
    }
    .flexer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include srbreakpoints-wrap-content-between(s, m) {
        display: block;
      }
    }
    .info-text {
      max-width: 44rem;
      @include srbreakpoints-wrap-content-between(s, m) {
        margin-bottom: 2rem;
        width: 100%;
        max-width: none;
      }
      h3 {
        color: #17475B;
        margin: 0 0 2rem;
        padding: 0;
        font-size: 2.4rem;
        @include srbreakpoints-wrap-content-between(s, m) {
          font-size: 1.8rem;
          line-height: 1.8rem;
          font-weight: normal;
          color: #000;
          margin-bottom: 3rem;
        }
      }
      p {
        color: #17475B;
        font-size: 1.6rem;
        line-height: 2.4rem;
        margin: 0;
      }
    }
    .size-facts {
      width: 25rem;
      color: #17475B;
      font-size: 1.6rem;
      line-height: 2.4rem;
      margin: 0;
      @include srbreakpoints-wrap-content-between(s, m) {
        margin-bottom: 4rem;
        width: 100%;
      }
      dt {
        float: left;
        width: 40%;
        font-weight: bold;
      }
      dd {
        margin-left: 40%;
        &:after {
          content: "";
          display: block;
          clear: both;
        }
      }
    }
    .button-on-light {
      height: 5rem;
      @include srbreakpoints-wrap-content-between(s, m) {
        margin-bottom: 4rem;
      }
    }
  }
  .room-image-slider {
    margin-top: 4rem;
    .room-picture {
      margin-right: 3rem;
      &:last-of-type {
        margin-right: 0;
      }
    }
    .picture {
      display: block;
      width: 40rem;
      height: 33rem;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
  .bx-wrapper {
    @include srbreakpoints-wrap-content-between(s, m) {
      margin-bottom: 4rem;
    }
  }
}


.service-triplet {
  ul {
    margin: 0;
    padding: 0 2rem 4rem;
    list-style: none;
    @include srbreakpoints-wrap-content-from(l) {
      display: flex;
      padding: 0;
    }
    li {
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      @include srbreakpoints-wrap-content-from(l) {
        width: 33.333333%;
        height: 100rem;
        display: flex;
      }
      @include srbreakpoints-wrap-content-between(s, m) {
        margin-bottom: 2rem;
        position: relative;
        &:last-of-type {
          margin-bottom: 0;
        }
        &:after {
          content: "";
          display: block;
          padding-bottom: 100%;
        }
      }
    }
    .info-layer {
      width: 100%;
      height: 100%;
      display: flex;
      text-decoration: none;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #fff;
      text-align: center;

      @include srbreakpoints-wrap-content-between(s, m) {
        position: absolute;
        background-color: rgba(#17475B, 0.8);
      }

      @include srbreakpoints-wrap-content-from(l) {
        background-color: rgba(#17475B, 0.2);
      }
      @include srbreakpoints-wrap-content-from(l) {
      }
      h3 {
        font-size: 4.8rem;
        line-height: 5.6rem;
      }
      p {
        @include srbreakpoints-wrap-content-from(l) {
          display: none;
        }
        max-width: 75%;
        margin: 2rem 0 3rem;
      }
      span.button-on-dark {
        display: none;
      }
      @include srbreakpoints-wrap-content-from(l) {
        &:hover {
          background-color: rgba(#17475B, 0.8);

          p, span.button-on-dark {
            display: block;
          }
        }
      }
    }
  }
}