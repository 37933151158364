.home-main-teaser {
  width: 100%;
  margin: 0 auto;
  height: 63rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 13rem 2rem 2rem;
  @include srbreakpoints-wrap-content-from(l) {
    width: calc(100% - 4rem);
    background-size: auto calc(100% - 4rem);
    height: 89rem;
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .info-area {
    width: 100%;
    background-color: rgba(#17475B, 0.9);
    padding: 5rem 3rem;
    text-align: center;
    color: #fff;
    @include srbreakpoints-wrap-content-from(l) {
      width: 67.6rem;
      height: 67.6rem;
      background-color: transparent;
      background-image: url("../assets/images/diamond.svg");
      background-size: 100%;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      justify-content: center;
      .content-wrapper {
        padding-top: 3rem;
        width: 36rem;
      }
    }
    h2 {
      font-size: 5.6rem;
      line-height: 6.4rem;
      font-weight: normal;
    }
    p {
      margin-bottom: 3.5rem;
    }
  }
}

.home-event-section {
  padding: 4rem 2rem;
  @include srbreakpoints-wrap-content-from(l) {
    padding: 0 0 13.4rem;
    position: relative;
    .text-section {
      padding: 16.8rem 0 9rem;
      margin-left: 16.8rem;
      width: 54rem;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 50rem;
      top: 0;
      right: 10.5rem;
      background-repeat: no-repeat;
      height: 37rem;
      background-image: url('../assets/images/arrows-grass.svg');
    }
  }

  .bx-wrapper {
    padding-top: 4rem;
  }

  .event-slider {
    .event {
      width: 100%;
      .picture {
        display: block;
        width: 100%;
        height: 26rem;
        background-size: auto 100%;
        background-repeat: no-repeat;
      }
      @include srbreakpoints-wrap-content-from(m) {
        margin-right: 2rem;
        width: calc(50% - 1rem);
      }
    }
    aside {
      padding: 2.5rem;
      background-color: #fff;
      h6 {
        text-transform: uppercase;
        font-size: 1.6rem;
        margin-bottom: 1.5rem;
        span {
          padding-left: 1rem;
          font-weight: normal;
          color: rgba(#17475B, 0.4);
        }
      }
      h2 {
        color: #000;
        font-size: 2.4rem;
        margin-bottom: 1.5rem;
      }
      .ticketlink {
        display: block;
        font-weight: bold;
        font-size: 1.6rem;
        color: #A3694E;
        text-transform: uppercase;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
